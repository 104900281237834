import React from "react";
import * as style from "../styles/about.module.css";
import Toggle from "../components/toggle/Toggle";
import { graphql } from "gatsby";
import { PortableText } from "@portabletext/react";
import Image from "gatsby-plugin-sanity-image";

const About = ({ data: { about } }) => {
    return (
        <main className={style.about}>
            <div className={style.toggleWrapper}>
                <Toggle to={""} colour={"#ffffff"} rotate />
            </div>
            <div className={style.text}>
                <PortableText value={about._rawHeadline} />
                <div className={style.description}>
                    <PortableText value={about._rawAbout} />
                </div>
            </div>
            <div className={style.image}>
                <Image alt="" {...about.image} width={1000} style={{ objectPosition: "100% 0%" }} />
            </div>
        </main>
    );
};

export default About;

export const query = graphql`
    query AboutQuery {
        about: sanityAbout {
            _rawAbout
            _rawHeadline
            image {
                ...ImageWithPreview
            }
        }
    }
`;
